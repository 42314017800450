@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;500;700&display=swap");
* {
  margin: 0;
  outline: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

//Scrollbar
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #000;
}

::-webkit-scrollbar-thumb {
  background: #b42401;
  border-radius: 2.5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #8b1c00;
}
body {
  padding-top: 100px;
  @media screen and (max-width: 920px) {
    padding-top: 60px;
  }
  &.modal-open {
    overflow: hidden;
  }
}

#root {
  background: #333;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100dvh;
  position: relative;
  padding-bottom: 80px;
  @media screen and (max-width: 920px) {
    padding-bottom: 150px;
  }
}
